@import '../setup/setup';

.pageRegister {
  //margin-top: 115px;
  padding-top: 60px;
  padding-bottom: 60px;
  flex: 1;
  display: flex;
  align-items: center;
  @include mq(tablet, max) {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rowVc {
  align-items: center;
}

.rowSb {
  justify-content: space-between;
}

.login {
  margin-top: 30px;
  text-align: center;

  a {
    text-decoration: underline;
    font-weight: 600;

    @include hover {
      text-decoration: none;
    }
  }
}

.subscription {
  padding: 30px;

  h1 {
    margin-bottom: 20px;
    font-size: $fs-mob-h1;
  }

  p {
    max-width: 750px;
    line-height: 25px;
    font-weight: 500;
  }
}

@include mq(tablet) {
  .subscription {
    padding: 40px;

    h1 {
      font-size: $fs-tab-h1;
    }
  }
}

@include mq(desktop-small) {
  .row {
    flex-direction: row;
  }

  .subscription {
    padding: 50px;

    h1 {
      margin-bottom: 40px;
      font-size: $fs-h1;
    }

    p {
      margin-bottom: 40px;
    }
  }
}
