@import '../setup/setup';

.block {
  position: relative;
  flex-basis: 100%;
  padding: 20px;
  margin: 0 auto 20px;
  max-width: 600px;

  &__buttons {
    display: flex;
    margin-top: 52px;
    justify-content: space-around;

    &--login {
      flex-direction: column;

      button:last-child {
        margin-top: 25px;
      }
    }
  }

  h2 {
    font-size: $fs-mob-h2;
    line-height: 1.2;
    font-family: $font-sans;
    margin-bottom: 50px;
  }

  p {
    //display: none;
    max-width: 500px;
    line-height: 1.2;
  }
}

.block.white {
  background: $color-white;
  color: $color-black;
}

.block.primary {
  background: $color-white;
  color: $color-dark;

  h2 {
    color: $color-black;
  }
}

@include mq(tablet) {
  .block {
    padding: 50px;

    &__buttons {
      display: flex;
      margin-top: 52px;
      justify-content: space-around;

      &--login {
        flex-direction: row;

        button:last-child {
          margin-top: 0px;
        }
      }
    }
  }
}

@include mq(desktop-small) {
  .block {
    margin-bottom: 0;

    &__buttons {
      display: flex;
      margin-top: 52px;
      justify-content: space-around;

      &--login {
        flex-direction: row;
      }
    }

    h2 {
      font-size: $fs-h3;
      line-height: 1.2;
      font-family: $font-sans;
      margin-bottom: 50px;
    }

    p {
      display: block;
    }
  }
}
